@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');
:host {
  font-family: 'Mulish', sans-serif;
}
body {
  font-family: 'Mulish', sans-serif;
  background-color: snow;
}
html, body { height: 100%; }
h1 {
  text-align: center;
  font-size: 32px;
  width: 100%;
}

@media screen and (max-width: 768px){
  h1 {
    background-color: rgba(190,140,175, 0.4);
  }
}

h2 {
  font-size: 24px;
}

p, a, article {
  font-size: 16px;
}
button {
  font-family: 'Mulish', sans-serif;
}

.stronger {
  font-weight: bolder;
}

.error {
  background-color: red;
  color: white;
}